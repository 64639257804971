@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@300;400;500;600;700;800&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@300;400;500;600;700;800&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,700&display=swap);
* {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0px;
  color: white;
  -webkit-font-smoothing: 'antialiased'
}

@media only screen and (max-width:768px) {
  h3{
      font-size: 1.1rem;
  }
}

@media only screen and (max-width:640px) {
  h3{
      font-size: 1rem;
  }
}

#body { 
  background-color: rgb(16, 16, 16);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.475);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
      
.npb {
  padding-bottom: 0px !important; 
}

noscript {
  position: absolute;
  left: 50%;
  top: 50%;
}

.particles-js {
    transition-duration: 0s;
    background-color: #000000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100vh;
}
.wrapper p, h1 {
    color: white;
    margin-top: 15px;
  }
  
  .intro h1, h2 {
    color: transparent;
    background-image: -moz-linear-linear-gradient(100deg, #2ecc71, #3498db, #9b59b6, #f39c12);
    background-image: linear-gradient(100deg, #2ecc71, #3498db, #9b59b6, #f39c12);
    background-clip: text;
    -webkit-background-clip: text;
    animation: chroma 10s ease-in-out infinite;
    -webkit-animation: chroma 10s ease-in-out infinite;
    -moz-animation: chroma 10s ease-in-out infinite;
    -o-animation: chroma 10s ease-in-out infinite;
    -ms-animation: chroma 10s ease-in-out infinite;
    background-size: 500%;
    -webkit-background-size: 500%; 
  }
  
  .wrapper h6 {
    color: gray;
    font-size: 10px;
    margin-top: 10px;
  }
  
  
  .wrapper {
    transition-duration: 0.5s;
    pointer-events: none;
    animation: fadeIn 0.4s;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    -ms-animation: fadeIn 0.4s;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    padding: 30px 40px;
    border-radius: 4px;
    min-width: 300px;
    max-width: 340px;
    min-height: 100px;
    border: 1px solid #222222;
  }
  
  @media only screen and (max-width: 693px){
    .wrapper {
        min-width: 230px;
    }
  }
  
  
  @media only screen and (max-height: 500px){
    .wrapper {
      min-width: 500px;
    }
  }

  .about-link {
    float: right;
    padding-top: 7px;
  }

  .about-link a {
    opacity: .75 !important
  }

  .about-link a:hover {
    opacity: 1 !important
  }
  
  code {
    font-family: monospace;
    color:rgb(255, 83, 112);
    font-size: 125%;
    opacity: 1 !important
  }
  
  .socials {
    margin-top: 18px;
    margin-bottom: 8px;
    transition-duration: 0.5s;
    pointer-events:all;
    align-content: 'center';
  }
  
  .socials a {
    transition-duration: 0.5s;
    opacity: 0.5;
  }
  
  .twitter-logo {
  margin-right: 7px;
  }
  
  .github-logo {
  margin-right: 7px;
  }
  
  .resume-logo {
    margin-right: 7px;
  }
  
  .twitter-logo:hover {
    opacity: 1;
  }
  
  .github-logo:hover {
    opacity:1;
  }
  
  .resume-logo:hover {
    opacity:1;
  }

  .email:hover {
    opacity:1;
  }

  /* keyframes */

  @keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-webkit-keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }

  .spotify-svg {
    transition-duration: 0.5s;
    opacity: 0.5;
    pointer-events: all;
  }

  /* .spotify-svg:hover {
    opacity: 1;
  } */
.spotify-status {
    overflow: hidden;
}

.spotify-status p {
    font-weight: 400;
    display: inline-flex;
    align-items: center;
}

.right {
    text-align: right;
}

.secret {
    line-height: 1.7;
    transition-duration: 0.5s;
    color: white;
    padding: 2rem 2.1rem 2rem 2.1rem;
    margin: 0 auto;
    max-width: 53rem;
    transition-duration: 0.5s;
}

@media only screen and (max-width: 1000px){
    .secret {
        max-width: 62rem;
        padding-top: 2rem;
        font-size: 0.9rem;;
    }
} 

.secret h3 {
    padding: 1.5rem 0 1.5rem 0;
    font-weight: 400;
}


.secret h1, h2 {
    color: white;
}

#home {
    border: none;
    background: rgb(170, 0, 255);
    border-radius: 4px;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 15px 0px 50px 0px;
    transition-duration: 0.7s;
    color: white;
    margin: 15px 100px 0 0;
    position: relative;
}
  
#home:hover {
    background: rgba(217, 0, 255, 0.71);
}

img {
    max-width: 45%; 
    height: auto;
  }
footer {
  margin-top: 1rem;
  max-width: 53rem;
  margin: 0 auto;
  text-decoration: none;
}

.bar {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  height: 3px;
  background-color: rgba(173, 181, 189, 0.105);
  border-radius: 2px;
}

.footer-container {
  padding-left: 0.25rem;
  display: inline-block;
  padding-bottom: 5rem;
}

footer h1, h4 {
  opacity: 0.3;
}


footer h4 {
  padding-bottom: 0.6rem;
}

footer a {
  text-decoration: none;
  color: rgba(173, 181, 189, 0.356);
  transition-duration: 0.2s;
}

footer a:hover {
  color: rgb(194, 194, 194);
}

@media only screen and (max-width:1030px) {
  footer {
    padding: 0 2.1rem;
  }
}

button  {
    border: none;
    background: rgb(170, 0, 255);
    border-radius: 4px;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    color: white;
    cursor: pointer;
}
  
button:hover {
    background: rgba(217, 0, 255, 0.71);
}

.gameCanvas {
    border: 1px solid rgb(170, 0, 255);
}

#gameScore {
    font-size: 30px;
    font-family: 'Ubuntu', sans-serif;
    color: rgb(170, 0, 255);
}

.bigDiv {
    text-align: center;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

}

.peekaboo {
    display: none;
}

.startButton {
    font-family: 'Ubuntu', sans-serif;
    color: rgb(170, 0, 255);
    font-size: 40px;
}
.intro-title h1 {
    font-size: 3.3rem;
    color: white;
}

.about {
    line-height: 1.6;
    color: white;
    padding: 2rem 2.1rem 2rem 2.1rem;
    margin: 0 auto;
    max-width: 53rem;
}

.back {
    line-height: 1.7;
    max-width: 54rem;
    padding: 4rem 2.1rem 0rem 2.1rem;
    margin: 0 auto;
    opacity:0.75;
    display: flex;
    justify-content: space-between;
    align-content: 'center';
}


@media only screen and (max-width:768px) {
    .intro-title h1{
        font-size: 2.9rem;
    }
}

@media only screen and (max-width:640px) {
    .intro-title h1{
        font-size: 2.3rem;
    }
    .res {
        display: none !important; 
    }
    .back {
        padding: 3rem 2.1rem 0rem 2.1rem;
    }
    .about {
        padding: 0.5rem 2.1rem 2rem 2.1rem;
    }
}

.about h3,strike {
    padding: 0.5rem 0 2.5rem 0;
    font-weight: 400;
    color: #e3e3e3;
}

.about p, span {
    color: #e3e3e3;
}

.about h2 {
    font-size: 1.7rem;
    color: white;
    font-weight: 600;
}

.card {
    border-radius: 3px;
    border: 1px solid gray;
    padding: 1.5rem;
    position: relative;
    transition-duration: .5s;
    /* margin-top: 2rem; */
}

.card a {
    transition-duration: 0.5s;
    float: right;
    text-decoration: none;
    color: white;
    margin-left: 10px;
    opacity: 0.5;
    padding-top: 11px;
}

.card:hover {
    border: 1px solid #d1d1d1;
    transition: border 0.5s;
}

.card a:hover {
    opacity: 1;
}

.card h6 {
    color: gray;
}

.card p, h6 {
    margin-top: 0.5rem;
}
.container {
    display: grid;
    grid-gap: 1.5rem;
}

@media (min-width: 800px) {
    .container { grid-template-columns: repeat(2, 1fr); }
  }

ul {
    list-style: none;
    margin: 0;
    padding-left:0;
    -webkit-columns: 5;
            columns: 5;
    text-align: center;
}

.tech {
    margin-top: 1.5rem;
}

li {
    text-align: inherit;
    display:flex;
    padding-bottom: 2.2rem;
}

@media (max-width: 700px) {
    ul { -webkit-columns: 2; columns: 2; }
  }

.description {
    line-height: 1.7;
    transition-duration: 0.5s;
    color: white;
    padding: 2rem 2.1rem 2rem 2.1rem;
    margin: 0 auto;
    max-width: 53rem;
    transition-duration: 0.5s;
}

@media only screen and (max-width: 1000px){
    .description {
        max-width: 53rem;
        padding-top: 2rem;
    }
} 

video,img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: 53rem;
    transition-duration: 0.5s;
}

.description * {
    margin-bottom: 0.8rem;
}

.cursor {
    position: fixed;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    pointer-events:none;
  }
  
  .cursors .cursor:nth-child(1) {
    background-color: rgb(208, 208, 208);  
    transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    z-index: 100002;
    display: none;
}

  .cursors .cursor:nth-child(2) {
    transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
    -ms-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    z-index: 100001;
    border:2px solid rgb(208, 208, 208);    
    height:18px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:18px;
    display: block;
  }
