.card {
    border-radius: 3px;
    border: 1px solid gray;
    padding: 1.5rem;
    position: relative;
    transition-duration: .5s;
    /* margin-top: 2rem; */
}

.card a {
    transition-duration: 0.5s;
    float: right;
    text-decoration: none;
    color: white;
    margin-left: 10px;
    opacity: 0.5;
    padding-top: 11px;
}

.card:hover {
    border: 1px solid #d1d1d1;
    transition: border 0.5s;
}

.card a:hover {
    opacity: 1;
}

.card h6 {
    color: gray;
}

.card p, h6 {
    margin-top: 0.5rem;
}