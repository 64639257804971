
.cursor {
    position: fixed;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events:none;
  }
  
  .cursors .cursor:nth-child(1) {
    background-color: rgb(208, 208, 208);  
    transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    z-index: 100002;
    display: none;
}

  .cursors .cursor:nth-child(2) {
    transition: opacity 0.1s ease-out;
    -moz-transition: opacity 0.1s ease-out;
    -webkit-transition: opacity 0.1s ease-out;
    -ms-transition: opacity 0.1s ease-out;
    -o-transition: opacity 0.1s ease-out;
    z-index: 100001;
    border:2px solid rgb(208, 208, 208);    
    height:18px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:18px;
    display: block;
  }