.intro-title h1 {
    font-size: 3.3rem;
    color: white;
}

.about {
    line-height: 1.6;
    color: white;
    padding: 2rem 2.1rem 2rem 2.1rem;
    margin: 0 auto;
    max-width: 53rem;
}

.back {
    line-height: 1.7;
    max-width: 54rem;
    padding: 4rem 2.1rem 0rem 2.1rem;
    margin: 0 auto;
    opacity:0.75;
    display: flex;
    justify-content: space-between;
    align-content: 'center';
}


@media only screen and (max-width:768px) {
    .intro-title h1{
        font-size: 2.9rem;
    }
}

@media only screen and (max-width:640px) {
    .intro-title h1{
        font-size: 2.3rem;
    }
    .res {
        display: none !important; 
    }
    .back {
        padding: 3rem 2.1rem 0rem 2.1rem;
    }
    .about {
        padding: 0.5rem 2.1rem 2rem 2.1rem;
    }
}

.about h3,strike {
    padding: 0.5rem 0 2.5rem 0;
    font-weight: 400;
    color: #e3e3e3;
}

.about p, span {
    color: #e3e3e3;
}

.about h2 {
    font-size: 1.7rem;
    color: white;
    font-weight: 600;
}
