button  {
    border: none;
    background: rgb(170, 0, 255);
    border-radius: 4px;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    color: white;
    cursor: pointer;
}
  
button:hover {
    background: rgba(217, 0, 255, 0.71);
}

.gameCanvas {
    border: 1px solid rgb(170, 0, 255);
}

#gameScore {
    font-size: 30px;
    font-family: 'Ubuntu', sans-serif;
    color: rgb(170, 0, 255);
}

.bigDiv {
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

}

.peekaboo {
    display: none;
}

.startButton {
    font-family: 'Ubuntu', sans-serif;
    color: rgb(170, 0, 255);
    font-size: 40px;
}