@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@300;400;500;600;700;800&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,700&display=swap');

.secret {
    line-height: 1.7;
    transition-duration: 0.5s;
    color: white;
    padding: 2rem 2.1rem 2rem 2.1rem;
    margin: 0 auto;
    max-width: 53rem;
    transition-duration: 0.5s;
}

@media only screen and (max-width: 1000px){
    .secret {
        max-width: 62rem;
        padding-top: 2rem;
        font-size: 0.9rem;;
    }
} 

.secret h3 {
    padding: 1.5rem 0 1.5rem 0;
    font-weight: 400;
}


.secret h1, h2 {
    color: white;
}

#home {
    border: none;
    background: rgb(170, 0, 255);
    border-radius: 4px;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 15px 0px 50px 0px;
    transition-duration: 0.7s;
    color: white;
    margin: 15px 100px 0 0;
    position: relative;
}
  
#home:hover {
    background: rgba(217, 0, 255, 0.71);
}

img {
    max-width: 45%; 
    height: auto;
  }