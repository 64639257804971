
ul {
    list-style: none;
    margin: 0;
    padding-left:0;
    columns: 5;
    text-align: center;
}

.tech {
    margin-top: 1.5rem;
}

li {
    text-align: inherit;
    display:flex;
    padding-bottom: 2.2rem;
}

@media (max-width: 700px) {
    ul { columns: 2; }
  }
