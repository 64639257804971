.spotify-status {
    overflow: hidden;
}

.spotify-status p {
    font-weight: 400;
    display: inline-flex;
    align-items: center;
}

.right {
    text-align: right;
}
