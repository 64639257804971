@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@300;400;500;600;700;800&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

* {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0px;
  color: white;
  -webkit-font-smoothing: 'antialiased'
}

@media only screen and (max-width:768px) {
  h3{
      font-size: 1.1rem;
  }
}

@media only screen and (max-width:640px) {
  h3{
      font-size: 1rem;
  }
}

#body { 
  background-color: rgb(16, 16, 16);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.475);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
      
.npb {
  padding-bottom: 0px !important; 
}

noscript {
  position: absolute;
  left: 50%;
  top: 50%;
}
