.wrapper p, h1 {
    color: white;
    margin-top: 15px;
  }
  
  .intro h1, h2 {
    color: transparent;
    background-image: -webkit-linear-gradient(100deg, #2ecc71, #3498db, #9b59b6, #f39c12);
    background-image: -moz-linear-linear-gradient(100deg, #2ecc71, #3498db, #9b59b6, #f39c12);
    background-image: linear-gradient(100deg, #2ecc71, #3498db, #9b59b6, #f39c12);
    background-clip: text;
    -webkit-background-clip: text;
    animation: chroma 10s ease-in-out infinite;
    -webkit-animation: chroma 10s ease-in-out infinite;
    -moz-animation: chroma 10s ease-in-out infinite;
    -o-animation: chroma 10s ease-in-out infinite;
    -ms-animation: chroma 10s ease-in-out infinite;
    background-size: 500%;
    -webkit-background-size: 500%; 
  }
  
  .wrapper h6 {
    color: gray;
    font-size: 10px;
    margin-top: 10px;
  }
  
  
  .wrapper {
    transition-duration: 0.5s;
    pointer-events: none;
    animation: fadeIn 0.4s;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    -ms-animation: fadeIn 0.4s;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2px);
    padding: 30px 40px;
    border-radius: 4px;
    min-width: 300px;
    max-width: 340px;
    min-height: 100px;
    border: 1px solid #222222;
  }
  
  @media only screen and (max-width: 693px){
    .wrapper {
        min-width: 230px;
    }
  }
  
  
  @media only screen and (max-height: 500px){
    .wrapper {
      min-width: 500px;
    }
  }

  .about-link {
    float: right;
    padding-top: 7px;
  }

  .about-link a {
    opacity: .75 !important
  }

  .about-link a:hover {
    opacity: 1 !important
  }
  
  code {
    font-family: monospace;
    color:rgb(255, 83, 112);
    font-size: 125%;
    opacity: 1 !important
  }
  
  .socials {
    margin-top: 18px;
    margin-bottom: 8px;
    transition-duration: 0.5s;
    pointer-events:all;
    align-content: 'center';
  }
  
  .socials a {
    transition-duration: 0.5s;
    opacity: 0.5;
  }
  
  .twitter-logo {
  margin-right: 7px;
  }
  
  .github-logo {
  margin-right: 7px;
  }
  
  .resume-logo {
    margin-right: 7px;
  }
  
  .twitter-logo:hover {
    opacity: 1;
  }
  
  .github-logo:hover {
    opacity:1;
  }
  
  .resume-logo:hover {
    opacity:1;
  }

  .email:hover {
    opacity:1;
  }

  /* keyframes */

  @keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-moz-keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-webkit-keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-o-keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }
  
  @-ms-keyframes chroma {
    0% {
      background-position: 0 100%;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 100%;
    }
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;
    }
  }
  
  @-moz-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-webkit-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-o-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .spotify-svg {
    transition-duration: 0.5s;
    opacity: 0.5;
    pointer-events: all;
  }

  /* .spotify-svg:hover {
    opacity: 1;
  } */