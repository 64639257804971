footer {
  margin-top: 1rem;
  max-width: 53rem;
  margin: 0 auto;
  text-decoration: none;
}

.bar {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  height: 3px;
  background-color: rgba(173, 181, 189, 0.105);
  border-radius: 2px;
}

.footer-container {
  padding-left: 0.25rem;
  display: inline-block;
  padding-bottom: 5rem;
}

footer h1, h4 {
  opacity: 0.3;
}


footer h4 {
  padding-bottom: 0.6rem;
}

footer a {
  text-decoration: none;
  color: rgba(173, 181, 189, 0.356);
  transition-duration: 0.2s;
}

footer a:hover {
  color: rgb(194, 194, 194);
}

@media only screen and (max-width:1030px) {
  footer {
    padding: 0 2.1rem;
  }
}
